import React from 'react';
import DefaultLayout from '../components/DefaultLayout';
import ConvertkitButton from '../components/ConvertkitButton';
import ConvertKit from '../components/ConvertKit';
import FeaturesGrid from '../components/FeaturesGrid';
import { navigate } from 'gatsby';

interface Props {}

const EcommerceAccounting: React.FC = () => {
  return (
    <DefaultLayout>
      <div className='container mx-auto px-6 lg:px-20 '>
        <div className='entry-content prose max-w-none mt-0 lg:mt-8 lg:mb-16'>
          <h2>Your dedicated eCommerce accounting team.</h2>
          <p>
            If you're running an eCommerce business, chances are you're using a variety of different cloud-based
            applications to manage your operations. This can make bookkeeping a challenge, since you have to keep track
            of all your transactions across multiple platforms.
          </p>
          <p>
            That's where Argento CPA comes in. We specialize in helping businesses streamline their bookkeeping
            processes by integrating with all the major cloud apps. This way, you can focus on running your business,
            while we take care of the back-end financials.
          </p>
          <p>
            We're more than just a bookkeeping service - we're your virtual accounting team. We'll work with you to
            customize a solution that fits your unique needs, and we're always available to answer any questions you may
            have. So if you're looking for a hassle-free way to manage your eCommerce finances, look no further and lets
            get started today.
          </p>
          <p>
            We specialize in working with clients who do business over the internet. Whether you run an eCommerce store
            or accept online payments, we'll work with you to tailor a solution that fits your specific needs, ensuring
            that you have the tools and resources you need to succeed.
          </p>
          <h3>Accurate monthly reporting</h3>
          <p>
            Our monthly reports include all the important metrics you need to track, from inventory statements and
            merchant fees to cost–of–goods–sold and more. Plus, we sync with all the most popular tools and platforms
            like Shopify, Amazon, Stripe, and Paypal, so you can be confident you're always getting accurate and
            up–to–date information. We are your Shopify accountant, and you'll have everything you need to keep your
            business on track and running smoothly month after month, all in one place.
          </p>
          <h3>We’re on your team</h3>
          <p>
            We know that starting a business is tough. There are a million things to think about and finance is often
            one of the last things on your mind. But we also know that a strong financial foundation is crucial for any
            business to succeed. That's why we're here. We want to be part of your team from the very beginning, helping
            you to establish the financial foundation you need to achieve your goals. We'll work with you to understand
            your business inside and out and identify the best tools and strategies for success. Together, we'll create
            a tailored plan that takes into account your short- and long-term goals. And we'll be with you every step of
            the way, providing support and advice when you need it most. So, if you're looking for a finance partner who
            will help you build a strong foundation for success, look no further. We're here to help you achieve your
            dreams.
          </p>
          <h3>Tax experts in your back pocket</h3>
          <p>
            If you're like most eCommerce businesses, you're always looking for ways to save on taxes. But with so many
            rules and regulations, it's hard to know where to start. That's where we come in. We're eCommerce
            accountants, and we know exactly how to help you minimize your tax liability. We'll work with you to ensure
            that you're taking advantage of all the tax breaks and incentives available to eCommerce businesses. We'll
            also help you stay compliant with all the latest sales tax rules and regulations. And because we're online
            accountants, we can do all of this without ever having to step foot in your office.
          </p>
        </div>
        <div className='entry-content prose max-w-none mt-0 lg:mt-8 lg:mb-16'>
          <ConvertkitButton formkitToggle='e0da3595ff'>Looking for tax tips and tricks?</ConvertkitButton>
          {/*<ConvertKit dataUid='e0da3595ff' />*/}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default EcommerceAccounting;
